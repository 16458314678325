
import React from 'react'
import ReactDOM from 'react-dom'
import 'regenerator-runtime/runtime'

import ManageCoupons from './admin/ManageCoupons'

window.initR = function (componentId) {
  const mountNode = document.getElementById(componentId)

  switch (componentId) {
    case 'ManageCoupons':
      ReactDOM.render(<ManageCoupons />, mountNode)
  }
}
