
import React, { useState, useCallback, useContext } from 'react'
import { parse, format } from 'date-fns'
import parseJSON from 'date-fns/parseJSON'
import { post, del } from '../rest'
import { copyTextToClipboard } from '../utils'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const base32Alphabet = [
  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K',
  'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V',
  'W', 'X', 'Y', 'Z', '2', '3', '4', '5', '6', '7',
  '8', '9'
]

const getRandomIntInclusive = (min, max) => {
  const randomBuffer = new Uint32Array(1)

  window.crypto.getRandomValues(randomBuffer)

  const randomNumber = randomBuffer[0] / (0xffffffff + 1)

  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(randomNumber * (max - min + 1)) + min
}

const generateRebateCode = () => {
  const res = []

  for (let i = 0; i < 5; i++) {
    res.push(
      base32Alphabet[getRandomIntInclusive(0, 31)]
    )
  }

  return res.join('')
}

const ManageCoupons = () => {
  const [numberOfCoupons, setNumberOfCoupons] = useState(10)

  const [coupons, setCoupons] = useState([])

  const [emailCouponsCreationProgress, setEmailCouponsCreationProgress] = useState(0)

  const [transferableCouponCreationProgress, setTransferableCouponCreationProgress] = useState(0)

  const [emailListInText, setEmailListInText] = useState('')

  const [rebatePercentage, setRebatePercentage] = useState('100')

  const [errorMsg, setErrorMsg] = useState('')

  const showLoadedCouponTable = emailCouponsCreationProgress === 0 && transferableCouponCreationProgress === 0

  const [expiryDate, setExpiryDate] = useState(null)

  const parseFields = () => {
    const rebate_percentage = Number.parseInt(rebatePercentage, 10)

    if (rebate_percentage > 100) {
      throw new Error(`Rebate cannot be ${rebatePercentage}`)
    }

    return [
      expiryDate, rebate_percentage
    ]
  }

  const createTransferableCoupons = () => {
    const res = []

    try {
      const [expiry_date_at, rebate_percentage] = parseFields()

      for (let i = 0; i < numberOfCoupons; i++) {
        res.push({
          code: generateRebateCode(),
          email_address: null,
          rebate_percentage,
          expiry_date_at
        })
      }

      setCoupons(res)
      setTransferableCouponCreationProgress(1)
    } catch (Error) {
      fail(`${Error}`)
    }
  }

  const saveToDb = () => {
    let _coupons = coupons

    if (emailCouponsCreationProgress > 0) {
      try {
        const [expiry_date_at, rebate_percentage] = parseFields()

        _coupons = emailListInText
          .trim()
          .split('\n')
          .map(s => s.trim())
          .filter(s => s !== '')
          .map(c => ({
            email_address: c,
            code: generateRebateCode(),
            rebate_percentage,
            expiry_date_at
          }))
      } catch (Error) {
        setErrorMsg(`${Error}`)
        return
      }
    }

    post('/api/1/admins/saveCoupons', _coupons)
      .then(res => {
        setCoupons(res.body.map(mapCouponFromJson))
        setTransferableCouponCreationProgress(0)
        setEmailCouponsCreationProgress(0)
        setEmailListInText('')
      })
  }

  const mapCouponFromJson = coupon => {
    const expiry_date_at = coupon.expiry_date_at === null ? null : parseJSON(coupon.expiry_date_at)

    return {
      ...coupon,
      expiry_date_at
    }
  }

  const loadAllCouponsClick = () =>
    fetch('/api/1/admins/coupons/all')
      .then(res => res.json())
      .then(res =>
        setCoupons(res.body.map(mapCouponFromJson))
      )

  const deleteCoupon = coupon =>
    del(`/api/1/admins/coupons/${coupon.id}`)
      .then(() => {
        setCoupons(coupons.filter(c => c.id !== coupon.id))
      })

  const loadRedeemedCoupons = () =>
    fetch('/api/1/admins/coupons/redeemed')
      .then(res => res.json())
      .then(res =>
        setCoupons(res.body.map(mapCouponFromJson))
      )

  const DATE_FORMAT = 'dd/MM/yyyy'

  const parseDate = s =>
    parse(s, DATE_FORMAT, new Date())

  const fail = msg => {
    setErrorMsg(msg)
    throw new Error(msg)
  }

  const cancelCreation = () => {
    setCoupons([])
    setEmailCouponsCreationProgress(0)
    setTransferableCouponCreationProgress(0)
    setEmailListInText('')
    setErrorMsg('')
  }

  const textAreaExample = `
bob@bob.com
bill@bill.org
alice@example.net
...
    `

  const emailCouponCreationPanel = () => <div className='row'>
    <div className='col-sm-6'>
      <div>
        <button className='btn btn-default btn-sm' onClick={saveToDb}>Save to Database</button>
        <button className='btn btn-default btn-sm' onClick={cancelCreation}>Cancel</button>
      </div>
      <label>Copy list of emails into textbox, one email per line:</label>
      <textarea
        onChange={e => {
          setEmailListInText(e.target.value)
        }}
        value={emailListInText}
        className='form-control'
        placeholder={textAreaExample}
        cols='20'
        rows='15'
      />
    </div>
                                         </div>

  const transferableCouponCreationPanel = () => <div className='row'>
    <div className='col-sm-12'>
      <button className='btn btn-default btn-sm' onClick={saveToDb}>Save to Database</button>
      <button className='btn btn-default btn-sm' onClick={cancelCreation}>Cancel</button>
      {couponTable()}
    </div>
                                                </div>

  const statusCell = coupon => {
    if (coupon.user_id) {
      return 'Redeemed'
    }
    return ''
  }

  const formatDateCell = d =>
    d !== null ? format(d, DATE_FORMAT) : 'on activation'

  const displayEmailCell = e => e === null ? '*' : e

  const couponTable = () => {
    const actionCell = coupon => {
      if (coupon.id) {
        return <a onClick={() => deleteCoupon(coupon)}>delete</a>
      }
      return null
    }

    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Email</th>
            <th>Code</th>
            <th>Rebate %</th>
            <th>Expires At</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {
          coupons.map(coupon => <tr key={coupon.code}>
            <td>{displayEmailCell(coupon.email_address)}</td>
            <td>{coupon.code}</td>
            <td>{coupon.rebate_percentage}</td>
            <td>{formatDateCell(coupon.expiry_date_at)}</td>
            <td>{statusCell(coupons)}</td>
            <td>{actionCell(coupon)}</td>
                                </tr>)
        }
        </tbody>
      </table>
    )
  }

  const couponsAsTsv = () => {
    const tsv = coupons.map(coupon => [
      displayEmailCell(coupon.email_address),
      coupon.code,
      coupon.rebate_percentage,
      formatDateCell(coupon.expiry_date_at),
      statusCell(coupon)
    ].join('\t')).join('\r\n')

    const header = [
      'Email', 'Code', 'rebate %', 'expiry', 'status'
    ].join('\t')

    return `${header}\r\n${tsv}`
  }

  const bottomPane = () => {
    if (emailCouponsCreationProgress > 0) {
      return emailCouponCreationPanel()
    }

    if (transferableCouponCreationProgress > 0) {
      return transferableCouponCreationPanel()
    }

    if (showLoadedCouponTable) {
      return (
        <div>
          <a className='btn btn-sm btn-info' onClick={() => copyTextToClipboard(couponsAsTsv())}>copy to clipboard</a>
          {couponTable()}
        </div>
      )
    }
  }

  const errorPanel = () => errorMsg === ''
    ? null
    : <div className='row'>
      <div className='col-md-10'>
        <div className='alert alert-danger' role='alert'>
          {errorMsg}
          <button className='btn btn-default' onClick={cancelCreation}>Ok</button>
        </div>
      </div>
    </div>

  const form = () =>
    <form className='form-inline'>
      <div className='form-group'>
        <label>Number of Coupons to create</label>
        <input
          type='number' value={numberOfCoupons}
          onChange={e => setNumberOfCoupons(e.target.value)}
          className='form-control'
          placeholder='number of transferable coupons'
        />
      </div>
      <div className='form-group'>
        <label>Rebate %</label>
        <input
          type='number' value={rebatePercentage}
          onChange={e => setRebatePercentage(e.target.value)}
          className='form-control'
          placeholder='rebate percentage'
        />
      </div>

      <div className='form-group'>
        <label>Expiry Date</label>
        <DatePicker
          selected={expiryDate}
          onChange={date => setExpiryDate(date)}
          isClearable
          minDate={new Date()}
        />
      </div>

    </form>

  return (
    <div>
      <div className='row'>
        <div className='col-md-8'>
          {form()}
          <span>
            <a className='btn btn-default btn-sm' onClick={createTransferableCoupons}>Create Transferable Coupons</a>
            <a className='btn btn-default btn-sm' onClick={() => setEmailCouponsCreationProgress(1)}>Create Non Transferable (email) Coupons</a>
            <a className='btn btn-default btn-sm' onClick={loadAllCouponsClick}>Load All Coupons</a>
            <a className='btn btn-default btn-sm' onClick={loadRedeemedCoupons}>Load Redeemed Coupons</a>
          </span>

        </div>
      </div>
      {errorPanel()}
      <div style={{ marginTop: 40 }} className='row'>
        <div className='col-md-8'>
          {bottomPane()}
        </div>
      </div>
    </div>
  )
}

export default ManageCoupons
