
export const copyTextToClipboard = text => {
  const tempInput = document.createElement('textarea')
  tempInput.style = 'position: absolute; left: -1000px; top: -1000px'
  tempInput.value = text
  document.body.appendChild(tempInput)
  tempInput.select()
  document.execCommand('copy')
  document.body.removeChild(tempInput)
}
