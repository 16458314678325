
const submitJson = (url, data, method) =>
  fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })

export const post = (url, data) => submitJson(url, data, 'POST').then(res => res.json())

export const del = (url, data) => submitJson(url, data, 'DELETE').then(res => res.json())
